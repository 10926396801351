import { useNavigate } from "react-router";
import Blog001 from "./Blogs/Blog001";
import Blog002 from "./Blogs/Blog002";
import Blog003 from "./Blogs/Blog003";
import Blog004 from "./Blogs/Blog004";
import Blog005 from "./Blogs/Blog005";
import Blog006 from "./Blogs/Blog006";
import Blog007 from "./Blogs/Blog007";
import Blog008 from "./Blogs/Blog008";
import Blog009 from "./Blogs/Blog009";
import Blog010 from "./Blogs/Blog010";
import Blog011 from "./Blogs/Blog011";
import Blog012 from "./Blogs/Blog012";
import Blog013 from "./Blogs/Blog013";
import Blog014 from "./Blogs/Blog014";
import Blog015 from "./Blogs/Blog015";
import Blog016 from "./Blogs/Blog016";
import Blog017 from "./Blogs/Blog017";
import Blog018 from "./Blogs/Blog018";
import Blog019 from "./Blogs/Blog019";
import Blog020 from "./Blogs/Blog020";
import Blog021 from "./Blogs/Blog021";
import Blog022 from "./Blogs/Blog022";
import Blog023 from "./Blogs/Blog023";
import Blog024 from "./Blogs/Blog024";
import Blog025 from "./Blogs/Blog025";
import Blog026 from "./Blogs/Blog026";
import Blog027 from "./Blogs/Blog027";
import Blog028 from "./Blogs/Blog028";
import Blog029 from "./Blogs/Blog029";
import Blog030 from "./Blogs/Blog030";
import Blog031 from './Blogs/Blog031';
import Blog032 from "./Blogs/Blog032";
import Blog033 from "./Blogs/Blog033";
import Blog034 from "./Blogs/Blog034";
import Blog035 from "./Blogs/Blog035";
import Blog036 from "./Blogs/Blog036";
import Blog037 from "./Blogs/Blog037";
import Blog038 from "./Blogs/Blog038";
import Blog039 from "./Blogs/Blog039";
import Blog040 from "./Blogs/Blog040";
import Blog041 from "./Blogs/Blog041";



export default function Blog() {
  const navigate = useNavigate();
  return (
    <div>
      
      
      <div className='w-full text-center'>
     <h1 className='text-2xl font-medium  '>Blog</h1>


     
     <div className='flex flex-row flex-wrap justify-center'>
      {/* CARD */}
<div onClick={()=>navigate('/blog/041')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog041 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                        {/* CARD */}
<div onClick={()=>navigate('/blog/040')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog040 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                  {/* CARD */}
<div onClick={()=>navigate('/blog/039')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog039 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
            {/* CARD */}
<div onClick={()=>navigate('/blog/038')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog038 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
      {/* CARD */}
<div onClick={()=>navigate('/blog/037')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog037 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
        {/* CARD */}
<div onClick={()=>navigate('/blog/036')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog036 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                  {/* CARD */}
<div onClick={()=>navigate('/blog/035')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog035 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
            {/* CARD */}
<div onClick={()=>navigate('/blog/034')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog034 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
      {/* CARD */}
<div onClick={()=>navigate('/blog/033')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog033 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
{/* CARD */}
<div onClick={()=>navigate('/blog/032')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog032 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
      {/* CARD */}
      <div onClick={()=>navigate('/blog/031')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog031 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
              {/* CARD */}
      <div onClick={()=>navigate('/blog/030')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog030 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                     {/* CARD */}
      <div onClick={()=>navigate('/blog/029')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog029 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/028')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog028 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/027')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog027 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/026')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog026 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/025')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog025 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/024')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog024 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/023')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog023 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/022')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog022 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/021')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog021 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/020')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog020 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/019')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog019 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/018')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog018 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/017')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog017 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/016')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog016 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/015')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog015 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/014')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog014 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/013')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog013 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/012')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog012 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/011')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog011 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/010')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog010 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/009')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog009 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/008')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog008 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/007')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog007 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/006')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog006 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/005')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog005 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/004')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog004 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/003')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog003 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                           {/*Card end*/}
                             {/* CARD */}
      <div onClick={()=>navigate('/blog/002')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog002 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}
                   {/*Card end*/}
                             {/* CARD */}
                             <div onClick={()=>navigate('/blog/001')}
      className='cursor-pointer lg:w-[29%] w-full shadow-xl border-4 m-4 flex flex-col justify-center items-center bg-slate-400 rounded-xl border-slate-500'>
          
         <div 
          className="shadow-lg w-[95%] h-[340px] border-4 rounded-md border-slate-500 hover:text-black text-slate-700 bg-slate-300 overflow-hidden mt-2 text-sm"><Blog001 /></div>
          <div className='mb-2 mt-2'>
          <p className='focus:text-blue-400 text-blue-700 hover:text-blue-300 m-4 text-sm'>Click to read more </p>

          </div></div> 
        {/*Card end*/}

        </div>
    </div>
    </div>
  )
}
